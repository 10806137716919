<template>
    <div class="setting-layout">
        <v-container class="pb-0">
            <v-row>
                <v-col cols="12">
                    <text-field :placeholder="$t('pages.search.placeholder')" @input="input" v-model="model">
                        <template v-slot:prepend-inner>
                            <v-icon>mdi-magnify</v-icon>
                        </template>
                    </text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="pt-2">
            <router-link v-if="user.id !== item.id" :to="{name: 'otherUser.profile.viewSlug', params: {slug: item.slug}}" v-for="(item, index) in result"
                         :key="index">
                <div class="item pl-3 pr-3">
                    <v-row align="center" :class="{'mt-4' : index !== 0}">
                        <v-col cols="2">
                            <profile-image
                                :src="getAvatarUrl(item.profile_image_url)"
                                :username="item.name"
                            />
                        </v-col>
                        <v-col cols="10">
                            <div class="d-flex align-center">
                                <span>
                                    {{ item.name }}
                                </span>
                                <verified-icon
                                    v-if="item.verify_at" 
                                    :height="15" :width="15" class="ml-1"/>
                                <holiday-mood-icon :height="18" :width="18" v-if="item.user_holiday_mood"/>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </router-link>
        </v-container>
        <bottom-bar :model="false" :active="true"></bottom-bar>
    </div>
</template>

<script>
import TextField from "@/components/app/form/TextField";
import BottomBar from "@/components/app/navigation/BottomBar";
import ProfileImage from "@/components/app/notifications/ProfileImage";
import VerifiedIcon from "@/components/app/common/VerifiedIcon";

import {mapActions, mapState} from "vuex";
import _ from 'lodash'
import HolidayMoodIcon from "@/components/app/icons/HolidayMoodIcon";

export default {
    name: "Search",

    components: {
        BottomBar,
        TextField,
        ProfileImage,
        VerifiedIcon,
        HolidayMoodIcon,
    },
    data: () => ({
        model: '',
        result: []
    }),
    computed:{
        ...mapState({
            user: state => state.otherUser.user,
        }),
        user() {
            return this.$auth.user()

        },
    },
    methods: {
        getAvatarUrl(url) {
            if (!url) {
                return ''
            }
            return url
        },
        ...mapActions(['setSearch']),
        input: _.debounce(function () {
            if (!this.model) {
                this.result = [];
                return;
            }

            this.$mixpanel.track('search_performed', {
                query: this.model,
            });
            this.setSearch({'filter[by_part_name]': this.model}).then(res => this.result = res.users)
        }, 200)
    },
    mounted() {
        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Search');
        return _.debounce(this.setSearch
            , 500)

    },
}
</script>

<style scoped>
.setting-layout {
    background: black;
    width: 100vw;
    min-height: 100%;
}

.profile-image {
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover;
}

.unblock {
    color: #FE4811;
    font-size: 0.9em;
}

.item {
    background: #1A1A1A;
    border-radius: 18px;
}
</style>
